import { environment } from "src/environments/environment";
import { RouteInfo } from "./vertical-sidebar.metadata";

export const ROUTES: RouteInfo[] = [
    {
        path: '/app/home',
        title: 'Home',
        icon:  'home',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/app/recent-activity',
        title: 'Recent Activity',
        icon:  'activity',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '',
        title: 'Search',
        icon: 'search',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: '/app/standard-search',
                title: 'Standard Search',
                icon: 'search',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/app/advance-search',
                title: 'Advanced Search',
                icon: 'search',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/app/csv-search',
                title: 'Multi SeriesUID File ',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [],
            },
        ]
    },

    {
        path: '',
        title: 'Upload',
        icon: 'upload',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: '/app/DICOM-upload',
                title: 'DICOM',
                icon: 'DICOM',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/app/upload',
                title: 'Documents',
                icon: 'Non-Dicom',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [],
            }
        ]
    },
    {
      path: '/app/about',
      title: 'About',
      icon: 'info',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }
];
