<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            (click)="sidebarnavItem.submenu.length != 0 ? '' : handleNotify()"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true"><i
                    [ngClass]="[sidebarnavItem.icon]"></i>
                    <span class="hide-menu">{{sidebarnavItem.title | translate}}</span>
            </div>
            <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="VerticalSidebarComponent_AddExpandClass(sidebarnavItem.title)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    <!-- <i [ngClass]="[sidebarnavItem.icon]"></i> -->
                    <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>
                    <span style="font-weight: 800;  font-size: 20px;padding-left: 10px;" id="menu-items"
                        class="hide-menu">{{sidebarnavItem.title | translate}}
                    <span *ngIf="sidebarnavItem.label != '' "  [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label | translate}}</span>
                    </span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <span *ngIf="sidebarnavSubItem.title =='Users'">
                        <a class="sidebar-link"
                            target=""
                            [href]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                            [routerLinkActive]="sidebarnavSubItem.submenu.length != 0 ? '' : 'router-link-active'"
                            [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                            (mouseover)="VerticalSidebarComponent_AddActiveClass(sidebarnavSubItem.title)" (click)="VerticalSidebarComponent_DisplayDotsDropdown()">
                            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                            <span class="hide-menu">{{sidebarnavSubItem.title | translate}}</span>
                        </a>
                    </span>
                    <span *ngIf="sidebarnavSubItem.title !=='Users'">
                        <a class="sidebar-link"
                            (click)="VerticalSidebarComponent_NavigateAndRefresh(sidebarnavSubItem.path)"
                            [routerLinkActive]="sidebarnavSubItem.submenu.length != 0 ? '' : 'router-link-active'"
                            [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                            (mouseover)="VerticalSidebarComponent_AddActiveClass(sidebarnavSubItem.title)">
                            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                            <span class="hide-menu">{{sidebarnavSubItem.title | translate}}</span>
                        </a>
                    </span>
                </li>
            </ul>
        </li>
    </ul>
