import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/DataService';
declare var $: any;

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html'
})
export class VerticalSidebarComponent implements OnDestroy {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  sNavigateToNewPath : string;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();


  handleNotify() {
    this.notify.emit(!this.showClass);
  }
  userType: string = '';
  sUserRole : string;
  constructor(private menuServise: VerticalSidebarService, private router: Router, private dataService: DataService,private oRouter : Router) {

  }
  ngOnInit()
  {
    this.sUserRole= localStorage.getItem('userRole');
    this.VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole();
  }
  ngOnDestroy(){
    // this.dataService.bDisplayDotsDropdown.next(false)
  }

VerticalSidebarComponent_NavigateAndRefresh(path)
{
  if(path == this.sNavigateToNewPath)
  {
    window.location.reload();
  }
  else
  {
    this.sNavigateToNewPath = path;
    this.oRouter.navigateByUrl(path);
  }
  // console.log("VerticalSidebarComponent_NavigateAndRefresh : Navigate to |====> ",path);
  // [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
}

VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole()
{
  if (this.sUserRole === 'admin')
  {
    this.menuServise.adminItems.subscribe(async menuItems =>
    {
      this.sidebarnavItems = menuItems;
      // Active menu
      if (menuItems)
      {
        this.sidebarnavItems = menuItems;
        // Active menu
        this.sidebarnavItems.filter(m => m.submenu.filter(
          (s) =>
          {
            if (s.path === this.router.url)
            {
              this.path = m.title;

            }
          }
        ));
        this.VerticalSidebarComponent_AddExpandClass(this.path);
      }
      else
      {
        this.sidebarnavItems = menuItems;
        // Active menu
        this.sidebarnavItems.filter(m => m.submenu.filter(
          (s) =>
          {
            if (s.path === this.router.url)
            {
              this.path = m.title;
            }
          }
        ));
        this.VerticalSidebarComponent_AddExpandClass(this.path);
      }
    });
  }
  else
  {
    this.menuServise.items.subscribe(async menuItems =>
    {
      this.sidebarnavItems = menuItems;
      // Active menu
      if (menuItems)
      {
        this.sidebarnavItems = menuItems;

        // Active menu
        this.sidebarnavItems.filter(m => m.submenu.filter(
          (s) =>
          {
            if (s.path === this.router.url)
            {
              this.path = m.title;
            }
          }
        ));
        this.VerticalSidebarComponent_AddExpandClass(this.path);
      }
      else
      {
        this.sidebarnavItems = menuItems;
        // Active menu
        this.sidebarnavItems.filter(m => m.submenu.filter(
          (s) =>
          {
            if (s.path === this.router.url)
            {
              this.path = m.title;
            }
          }
        ));
        this.VerticalSidebarComponent_AddExpandClass(this.path);
      }
    });
  }
console.log("User Role : ==>  '%s' && Menu Items :  ==> ",this.sUserRole, this.sidebarnavItems);
}

VerticalSidebarComponent_AddExpandClass(element: any)
{
  if (element === this.showMenu)
  {
    this.showMenu = '0';
  } else
  {
    this.showMenu = element;
  }
}

VerticalSidebarComponent_AddActiveClass(element: any)
{
  if (element === this.showSubMenu)
  {
    this.showSubMenu = '0';
  } else
  {
    this.showSubMenu = element;
  }
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}
VerticalSidebarComponent_DisplayDotsDropdown()
{
  console.log("working");
  // this.dataService.bDisplayDotsDropdown.next(true)
}
}
